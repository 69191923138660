<template>
	<div class="main-contents">
		<div class="mem_container resume">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div class="title-txt">내 프로필 관리<br/><span>이력서 및 NCS 직무자가평가</span></div>
				</div>
				<div class="tab-type1">
					<ul class="tab">
						<li @click="pageMove('res')">
							<a><img src="/images/tec/prf/tab1.png" alt="이력서탭"/>이력서</a>
						</li>
						<li @click="pageMove('asgMng')">
                            <!-- <div v-if="asgRegYn == 'N'" class="alarm show"><img src="/images/tab_alarm.png" alt="미등록항목알람"><p>미등록 항목이 있습니다!</p></div> -->
                            <a><img src="/images/tec/prf/tab3.png" alt="소속사관리탭"/>소속사 관리</a>                            
                        </li>
						<li class="on">
							<a><img src="/images/tec/prf/tab2.png" alt="직무자가평가탭"/>NCS 직무 자가평가</a>
						</li>
					</ul>
				</div>
				<div class="contents-body tab">
					<div class="duty-box">
						<div class="tit">NCS (국가직무능력표준, National Competency Standards)</div>
						<div class="intro">
							산업현장에서 직무를 수행하는 데 필요한 능력(지식, 기술, 태도)을 국가가 표준화한 것입니다.<br/>
							교육훈련· 자격에 NCS를 활용하여 현장중심의 인재를 양성할 수 있도록 지원하고 있습니다.
						</div>
					</div>
					<!-- NCS 직무 자가진단 평가 -->
					<div class="my-ncs">
						<div class="my-ncs-tit">
							NCS 직무 자가진단 평가
							<span class="ps">※ NCS 직무 자가진단 평가를 여러개 진행할 경우, 직무분야를 선택한 후 추가버튼을 클릭해 주세요.</span>
						</div>
						<div class="ncs-section">
							<div class="ncs-clas">
								<div class="clas-tit">대분류</div>
								<div class="clas-select">
									<div class="select_wrap clas">
										<select disabled>
											<option value="" selected disabled hidden>{{ncsClsfInfo.majorClassNm}}</option>
										</select>
									</div>
								</div>
							</div>
							<div class="ncs-clas">
								<div class="clas-tit">중분류</div>
								<div class="clas-select">
									<div class="select_wrap clas">
										<select disabled>
											<option value="" selected disabled hidden>{{ncsClsfInfo.midClassNm}}</option>
										</select>
									</div>
								</div>
							</div>
							<div class="ncs-clas">
								<div class="clas-tit">소분류</div>
								<div class="clas-select">
									<div class="select_wrap clas">
										<select disabled>
											<option value="" selected disabled hidden>{{ncsClsfInfo.minorClassNm}}</option>
										</select>
									</div>
								</div>
							</div>
							<div class="ncs-clas">
								<div class="clas-tit">직무분야</div>
								<div class="clas-select">
									<div class="select_wrap clas">
										<select disabled>
											<option value="" selected disabled hidden>{{ncsClsfInfo.detailClassNm}}</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="duty-box notice">
						<div class="intro">
							<span class="ico_info red">!</span>
							NCS Level 항목에 표시된 Level은 본인이 등록한 기술신고서에 의해 자동으로 산출된 Level이므로,<br/>
							<span class="ico_info"></span>
							각 Level에 따른 능력단위명에 해당하는 자가진단을 하여야 합니다.<br/>
							<span class="ico_info"></span>
							모든 능력단위명을 진단완료하여야 최종적으로 본인의 기술등급이 등록됩니다.
						</div>
					</div>

					<!-- 자가진단 작성 -->
					<div class="duty-box">
						<div class="tit write" ref="evalTitle">자가진단 작성</div>
						<div class="intro write">
							{{ncsClsfInfo.detailClassNm}} > {{ncsClsfInfo.abilUnitNm}}
						</div>
						<div class="Board type3">
							<table class="Board_type3">
								<colgroup>
									<col width="10%">
									<col width="65%">
									<col width="5%">
									<col width="5%">
									<col width="5%">
									<col width="5%">
									<col width="5%">
								</colgroup>
								<thead>
									<tr>
										<th>진단영역</th>
										<th>진단문항</th>
										<th>매우<br/>미<span @click="allClickRdo('1')">흡</span></th>
										<th>미<span @click="allClickRdo('2')">흡</span></th>
										<th>보<span @click="allClickRdo('3')">통</span></th>
										<th>우<span @click="allClickRdo('4')">수</span></th>
										<th>매우<br/>우<span @click="allClickRdo('5')">수</span></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, rowIdx) in ncsSelfEvalQstList" :key="rowIdx">
										<td v-if="row.mergeChkCnt == row.mergeCnt" :rowspan="row.mergeChkCnt" class="lev">{{row.abilUnitElemNm}}</td>
										<td>
											{{row.qeusCont}}
										</td>
										<td class="radio" v-for="rdo in radioList" :key="rdo">
											<input type="radio" :name="row.selfDiagQeusCd" :id="row.selfDiagQeusCd + '_' + rdo" :disabled="ncsClsfInfo.ncsGradeCd && row.chk != rdo" @click="chkRdo(rowIdx, rdo)" :checked="row.chk == rdo">
											<label :for="row.selfDiagQeusCd + '_' + rdo">
												<span></span>
											</label>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<!-- 자가진단 결과 -->
					<div class="duty-box">
						<div class="tit result">자가진단 결과</div>
						<div class="intro write mt">{{ncsClsfInfo.detailClassNm}} > {{ncsClsfInfo.abilUnitNm}}</div>
						<div class="Board type3">
							<table class="Board_type3">
								<colgroup>
									<col width="70%">
									<col width="15%">
									<col width="15%">
								</colgroup>
								<thead>
									<tr>
										<th>진단영역</th>
										<th>문항수</th>
										<th>점수</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, avgIdx) in ncsSelfEvalAvgList" :key="avgIdx" :class="row.elemNm == '합계' ? 'sum' : ''">
										<td>{{row.elemNm}}</td>
										<td class="score">{{row.queCnt}}</td>
										<td class="score">{{row.sum}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				
				<!-- 하단 버튼 -->
				<div class="select_btn">
					<div class="btn_01" @click="pageMove('ncs')">취소</div>
					<div class="btn_02" @click="submit()">진단결과 저장</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			// 기본정보
			ncsClsfInfo : {},

			// NCS 자가진단 문항
			ncsSelfEvalQstList : [],

			// NCS 자가진단 평균
			ncsSelfEvalAvgList : [],

			// 넘어온 데이터
			forwardData : this.$route.params.secrets,

			radioList : ['1', '2', '3', '4', '5'],

			// 소속사 등록 확인
			// asgRegYn : '',
		}
	},

	beforeMount() {
		// this.asgRegChk();

		if(this.forwardData) {
			// 초기 데이터 조회
			this.selectInitData();
		}else {
			this.$router.replace('PRF102M02');
		}
	},

	methods : {
		// 소속사 등록 여부 확인
		// asgRegChk(){
        //     this.$.httpPost('/api/mem/prf/getMberAsgRegInfo', {mberSeq : this.$store.state.userInfo.mberSeq})
        //         .then(res => {
        //             this.asgRegYn = res.data.mberAsgRegInfo.mberAsgRegYn;
        //         })
        //         .catch(err => {
        //             alert(err.response.data.error_description);
        //         });
        // },

		// 기본 정보 조회
		selectInitData() {
			this.$.httpPost('api/mem/prf/selectNcsSelfEvalQst', this.forwardData, '')
				.then(res => {
					// console.log('RESULT :', res);

					if(res.data.ncsClsfInfo) this.ncsClsfInfo = res.data.ncsClsfInfo;
					if(res.data.ncsSelfEvalQstList) this.ncsSelfEvalQstList = res.data.ncsSelfEvalQstList;
					if(res.data.ncsSelfEvalAvgList) this.ncsSelfEvalAvgList = res.data.ncsSelfEvalAvgList;

					this.$.focus(this.$refs.evalTitle);
				})
				.catch(this.$.httpErrorCommon);
		},

		// 라디오버튼 클릭
		chkRdo(rowIdx, rdoVal) {
			var row = this.ncsSelfEvalQstList[rowIdx];
			row.chk = rdoVal;
			if(!row.cuDiv) row.cuDiv = 'U';
			
			var eleCd =  row.abilUnitElemCd;
			var eleSum = 0; // 각 영역 점수 합
			var totSum = 0; // 총 영역 점수 합
			
			// 점수 합
			for(var i in this.ncsSelfEvalQstList) {
				var sltEleCd = this.ncsSelfEvalQstList[i].abilUnitElemCd;
				var score = Number(this.ncsSelfEvalQstList[i].chk == null ? 0 : this.ncsSelfEvalQstList[i].chk);
				
				if(sltEleCd == eleCd) {
					eleSum += score;
				}
				
				totSum += score;
			}
			
			// 점수/문항 수
			for(var n in this.ncsSelfEvalAvgList) {
				var avgEleCd = this.ncsSelfEvalAvgList[n].elemCd;
				
				if(avgEleCd == eleCd) {
					var eleQst = Number(this.ncsSelfEvalAvgList[n].queCnt);
					
					this.ncsSelfEvalAvgList[n].sum = eleSum;
					this.ncsSelfEvalAvgList[n].avg = (eleSum / eleQst).toFixed(2);
				}
			}
			
			// 합계
			var tot = this.ncsSelfEvalAvgList[this.ncsSelfEvalAvgList.length - 1];
			//var totQst = Number(tot.queCnt);
			
			tot.sum = totSum;
			//tot.avg = (totSum / totQst).toFixed(2);

			//console.log(this.ncsSelfEvalAvgList);
		},

		// 진단결과 저장
		submit() {
			var qstAry = [];

			for(var i in this.ncsSelfEvalQstList) {
				var row = this.ncsSelfEvalQstList[i];

				// console.log('ROW :', row);

				if(!row.chk) {
					alert('자가진단이 완료되지 않았습니다.');
					return;
				}

				var qstObj ={};
				
				if(row.cuDiv) {
					qstObj.qstSeq = row.selfDiagQeusSeq;
					qstObj.score = row.chk;
					qstObj.cuDiv = row.cuDiv;
					if(row.selfDiagResultSeq) qstObj.resSeq = row.selfDiagResultSeq;
					
					qstAry.push(qstObj);
				}
			}

			if(qstAry.length > 0) {
				var param = {};
				param.techGradeVerSeq = this.forwardData.techGradeVerSeq;
				param.mberTechGradeFinalSeq = this.forwardData.mberTechGradeFinalSeq;
				param.techGrade = this.forwardData.techGrade;
				param.detailClassCd = this.ncsClsfInfo.detailClassCd;
				param.questList = qstAry;
				
				// console.log('SEND PARAMETER :', param);

				this.$.httpPost('api/mem/prf/updateNcsSelfEvalQst', param, '')
					.then(() => {
						// console.log('Data Update Result :', res);
						
						alert('저장이 완료되었습니다.');
						this.pageMove('ncs');
						//this.selectInitData();
					})
					.catch(err => {
						// console.log('============================='); 
						// console.log(err);
						// console.log(err.response);
						// console.log('============================='); 

						alert(err.response.data.error_description);
					})
			} else {
				alert("변경된 내용이 없습니다.");
				this.$router.go(-1);
			}
		},

		pageMove(div) {
            if(div == 'res') {
                this.$router.push('PRF101M01');
            } else if(div == 'asgMng') { 
                this.$router.push('PRF104M01');
            } else {
				this.$router.go(-1);
			}    
		},

		allClickRdo(val) {
			if(this.ncsSelfEvalQstList[0].ncsLevelCd == null) {
				for(var i in this.ncsSelfEvalQstList) {
					this.ncsSelfEvalQstList[i].chk = val;
					
					this.chkRdo(i, val);
				}
			}
		}
	}
}
</script>